.data-grid .MuiDataGrid-cell {
  justify-content: center;
  align-items: center;
  height: 40px;
  text-align: center;
}

.data-grid .MuiDataGrid-columnHeaderTitleContainer {
  justify-content: center;
  align-items: center;
  text-align: center;
}

.centeredCell {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 40px;
  vertical-align: middle;
}
