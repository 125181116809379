$breakpoint-mobile: 480px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;

.dashboard-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
  gap: 10px;

  .chart-container {
    flex: 1 1 45%;
    min-width: 300px;
    padding: 10px;
    box-sizing: border-box;
    max-width: 100%;

    .MuiChart-root {
      max-width: 100%;
      overflow: hidden;
    }
  }
}
