.table-parts-container {
  padding: 16px;

  .cell-clickable {
    cursor: pointer;
    font-weight: bold;
    text-decoration: underline;
    color: var(--primary-color);
  }

  .filters-container {
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 16px;
    margin: 16px;
  }

  .table-container-data-grid {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    // padding: 16px;
    // margin: 16px;
    border: none !important;
  }

  .small td {
    padding: 4px;
    font-size: 0.875rem;
  }

  .medium td {
    padding: 8px;
    font-size: 1rem;
  }

  .large td {
    padding: 25px;
    font-size: 1.125rem;
  }

  .filters-button {
    margin-bottom: 16px;
  }

  .filters-row {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    margin-bottom: 16px;
    padding: 0 10px;
  }

  .filter-item {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .MuiFormControl-root {
      min-width: 200px;
      border-radius: 20px;
    }

    .MuiSwitch-root {
      margin-left: -10px;
    }
  }

  .action-row {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;

    .action-label {
      font-size: 0.75rem;
      color: rgba(0, 0, 0, 0.6);
      margin-bottom: 2px;
      border-radius: 20px;
    }

    .select-and-button {
      display: flex;
      align-items: stretch;
      width: 100%;

      .go-button {
        margin-left: 10px;
        text-transform: uppercase;
      }
    }
  }
  .table-container {
    padding: 16px;
    margin: 16px;
  }

  .MuiPaper-root {
    box-shadow: none !important;
  }
  .MuiTableCell-root {
    border-bottom: 1px solid #e0e0e0;
    padding: 8px;
  }

  .MuiTableHead-root {
    background-color: #f5f5f5;
  }
  .MuiBox-root {
    background-color: #ffffff !important;
  }

  .life-stage-early {
    background-color: #4caf50;
  }
}
