.side-nav-outer-toolbar {
  flex-direction: column;
  display: flex;
  height: 100%;
  width: 100%;
}

.layout-header {
  z-index: 1501;
}
@import '../../dx-styles.scss';
@import '../../themes/generated/variables.additional.scss';

.side-navigation-menu {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: 100%;
  padding-left: 40px;
  padding-right: 40px;

  .menu-container {
    min-height: 100%;
    display: flex;
    flex: 1;

    .dx-treeview {
      // ## Long text positioning
      white-space: nowrap;
      // ##

      // ## Icon width customization
      .dx-treeview-item {
        padding-left: 0;
        flex-direction: row-reverse;

        .dx-icon {
          width: $side-panel-min-width !important;
          margin: 0 !important;
        }
      }
      // ##

      // ## Arrow customization
      .dx-treeview-node {
        padding: 0 0 !important;
      }

      .dx-treeview-toggle-item-visibility {
        right: 10px;
        left: auto;
      }

      .dx-rtl .dx-treeview-toggle-item-visibility {
        left: 10px;
        right: auto;
      }
      // ##

      // ## Item levels customization
      .dx-treeview-node {
        &[aria-level='1'] {
          font-weight: bold;
          border-bottom: 1px solid $base-border-color;
        }

        &[aria-level='2'] .dx-treeview-item-content {
          font-weight: normal;
          padding: 0 $side-panel-min-width;
        }
      }
      // ##
    }

    // ## Selected & Focuced items customization
    .dx-treeview {
      .dx-treeview-node-container {
        .dx-treeview-node {
          &.dx-state-selected:not(.dx-state-focused) > .dx-treeview-item {
            background: transparent;
          }

          &.dx-state-selected > .dx-treeview-item * {
            color: $base-accent;
          }

          &:not(.dx-state-focused) > .dx-treeview-item.dx-state-hover {
            background-color: lighten($base-bg, 4);
          }
        }
      }
    }

    .dx-theme-generic .dx-treeview {
      .dx-treeview-node-container
        .dx-treeview-node.dx-state-selected.dx-state-focused
        > .dx-treeview-item
        * {
        color: inherit;
      }
    }
    // ##
  }
}

.dx-drawer-overlap.pre-init-blink-fix {
  @import '../../utils/patches.scss';
  @include menu-pre-init-patch;
}

.sidenav-accordion {
  background-color: #061939;

  .dx-accordion-item {
    color: #ffffff;
    background-color: transparent;
    box-shadow: none;
    margin-bottom: 0;
    margin-top: 0;

    .dx-accordion-item-body {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .dx-accordion-item-title {
    background-color: transparent;
    padding-top: 0;
    padding-bottom: 0;
  }
  .dx-accordion-item-title::before {
    inset-inline-start: 100px;
  }

  .dx-accordion-item.dx-state-focused > .dx-accordion-item-title {
    color: #ffffff;
  }
}

.accordion-content-item {
  padding-left: 16px;

  &:hover {
    color: #306eb6;
    border-left: #306eb6 2px solid;
    cursor: pointer;
  }

  &.selected {
    color: #306eb6;
    border-left: #306eb6 2px solid;
  }
}

.dx-treeview {
  margin-top: 20px;
}

.dx-item .dx-treeview-item .dx-state-focused {
  background-color: #ffffff !important;
}
