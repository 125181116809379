.nsn-detail-container {
  display: grid;
  grid-template-columns: 300px auto;
}

.nsn-detail-label {
  font-weight: 500;
  width: 300px;
}

.nsn-detail-content {
  flex-grow: 1;
}
