.data-grid-button {
  font-size: 14px !important;
}

.padding-top {
  padding-top: 10px;
}

.popup-footer {
  padding-top: 15px;
  display: flex;
  justify-content: space-between;
}

.create-button {
  border-radius: 10px !important;
  margin: 0px !important;
  background-color: #0c72b5 !important;

  &:hover {
    background-color: #00406e !important;

    .dx-button-content {
      background-color: inherit !important;
    }
  }
}

.cancel-button {
  border-radius: 10px !important;
  margin: 0px !important;

  &:hover {
    .dx-button-content {
      background-color: rgb(229, 231, 244) !important;
    }

    .dx-inkripple-wave {
      background-color: rgba(116, 117, 126, 0.377) !important;
    }
  }
}

.data-grid {
  .dx-datagrid-headers {
    border-radius: 10px 10px 0 0 !important;
  }

  .dx-datagrid-rowsview {
    border-radius: 0 0 10px 10px !important;
  }
}
.data-grid .dx-cell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.no-pagination .MuiTablePagination-root {
  display: none;
}

.data-grid-container {
  max-height: 500px;
  overflow-y: auto;
}
