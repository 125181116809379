.tree-view-container {
  max-height: 60vh;
  margin-top: 50px;
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #007ec1 !important;
    border-radius: 10px;
    border: 2px solid #061939;
  }

  &::-webkit-scrollbar-track {
    background: #061939;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #3b8fbd;
  }

  .spinner {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 50px;
  }

  .tree-view-item {
    padding: 5px;

    &:hover {
      border-left: 2px solid #306eb6;
      cursor: pointer;
      background-color: transparent;
      color: #306eb6;
      padding-left: 3px;
      box-sizing: border-box;
    }

    &.title {
      font-weight: 500;
    }
    &.selected {
      background-color: transparent;
      color: #306eb6;
    }
  }

  .dx-treeview-item {
    background-color: transparent;
    padding-left: 0;

    &:hover {
      background-color: transparent;
      color: #306eb6;
    }
  }

  .dx-state-hover {
    background-color: transparent !important;
  }

  .dx-treeview-node .dx-treeview-item .dx-state-focused {
    background-color: transparent !important;
  }

  .non-selectable {
    color: white !important;
    pointer-events: none;
  }

  .dx-treeview {
    margin-top: 0;
  }
}

.dx-swatch-additional .dx-treeview-node {
  padding-inline-start: 8px !important;
}
