.validate-button {
  margin-left: -80px;
  margin-bottom: -65px;
}

.dx-field-item.dx-field-button-item {
  text-align: left !important;
  .dx-widget {
    font-size: 12px;
  }
}
