.popup-article {
  width: 100%;
  max-width: 800px;
  margin: 10px !important ;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;

  .popup-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    background-color: #0078d4;
    color: white;
  }

  .popup-header h2 {
    margin: 0;
    font-size: 2.5rem !important;
  }

  .dx-toolbar-after {
    padding-right: 25px !important;
    color: #333333;
  }

  .dx-icon .dx-icon-close {
    color: #333333 !important;
  }

  .dx-swatch-additional .dx-button-mode-text .dx-icon {
    color: #333333 !important;
  }

  .dx-swatch-additional .dx-button .dx-icon {
    color: #0078d4 !important;
  }

  .toolbar-buttons {
    display: flex;
    gap: 10px;
  }

  .toolbar-buttons .dx-button {
    background-color: #fff;
  }

  .toolbar-buttons .dx-button:hover {
    background-color: #fff !important;
  }

  .dx-button-content:hover {
    background-color: #e4e4e4 !important;
  }

  .dx-swatch-additional .dx-button:focus {
    background-color: #fff;
  }

  .dx-button .dx-icon {
    color: #333333 !important;
  }

  .dx-swatch-additional .dx-button-has-icon .dx-icon {
    font-size: 20px;
  }

  .popup-content {
    padding: 20px;
    color: #333;
    font-size: 1rem;
    line-height: 1.5;
    gap: 20px;
    display: flex;
    flex-direction: column;

    .general-info {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin: 0px;

      .info-text {
        flex: 1;
        display: flex;
        flex-direction: column;
        line-height: 1;
        max-width: 350px;
        .info-text-url {
          display: block;
          word-wrap: break-word;
          width: 100%;
          overflow-wrap: break-word;
        }
      }

      .article-image {
        max-width: 350px;
        max-height: 200px;
        object-fit: cover;
        margin-right: 15px;
      }
    }
  }

  .general-info,
  .summary,
  .article-content {
    margin-bottom: 20px;
  }

  .supplier-tags .tag,
  .kwm-keywords .tag {
    display: inline-block;
    background-color: #f3f3f3;
    padding: 5px 10px;
    margin: 5px;
    border-radius: 5px;
    font-size: 0.875rem;
    color: #0078d4;
  }

  .popup-footer {
    text-align: right;
    padding: 10px 20px;
    background-color: #f3f3f3;
  }

  .popup-footer .dx-button {
    background-color: #0078d4;
    color: #fff;
  }

  .popup-footer .dx-button:hover {
    background-color: #005a9e;
  }
}

.dx-popup-title.dx-toolbar .dx-toolbar-items-container {
  height: 5vh !important;
}
.dx-popup-content {
  padding: 0px;
}
.dx-swatch-additional .dx-button-mode-text .dx-icon {
  color: #333;
}

.dx-popup-content-scrollable::-webkit-scrollbar {
  width: 10px;
}
.dx-popup-content-scrollable::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 10px;
}

.dx-popup-content-scrollable::-webkit-scrollbar-thumb {
  background-color: #007ec1;
  border-radius: 10px;
  border: 2px solid #fff;
}

.dx-popup-content-scrollable::-webkit-scrollbar-thumb:hover {
  background-color: #3b8fbd;
}
