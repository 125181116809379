.table-container {
  margin: 20px;
  padding: 20px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  .table {
    width: 100%;
    border-collapse: collapse;

    th {
      background-color: #2f87df;
      color: white;
      padding: 12px;
      text-align: left;
      min-width: 150px;
    }

    th,
    td {
      border-bottom: 1px solid #ddd;
      text-align: center;
    }

    tr:hover {
      background-color: #f1f1f1;
    }

    td {
      padding: 12px;
    }

    .action-button {
      color: #007bff;
      text-decoration: underline;
    }

    .icon-button {
      color: gray;
    }
  }
}

.table-container.no-padding-shadow {
  margin: 0;
  padding: 0;
  box-shadow: none;
}
