.expanded-table-content {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 16px;
  margin: 16px;


  &.standard {
    .MuiDataGrid-row {
      max-height: 52px !important; 
      min-height: 52px !important;
      --height: 52px !important; 
      border-bottom: 1px solid #e0e0e0;     
  }
}

  &.comfortable {
    .MuiDataGrid-row {
      max-height: 67px !important;
      min-height: 67px !important;
      --height: 67px !important;  
      border-bottom: 1px solid #e0e0e0;
    }
    
  }

  &.compact {
    .MuiDataGrid-row {
      max-height: 36px !important;  
      min-height: 36px !important;
      --height: 36px !important;  
      border-bottom: 1px solid #e0e0e0;
    }
  }

 
  .expanded-table-head {
    background-color: #ffffff !important;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    // margin: 16px;

    .expanded-table-row {
      border-bottom: 1px solid #e0e0e0;
    }
  }
  .css-1jlz3st{
    border-top: none !important;
    
  }
}