.overview-container {
  position: relative;
  min-height: 100vh;

  .measures-title {
    font-size: 20px;
    font-weight: 400;
    color: #0e0e0e;
    letter-spacing: 0.05em;
    margin-bottom: 15px;
  }
  .loading-container {
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
