.suppliers-menu-add-button-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;

  .suppliers-menu-add-button {
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  .suppliers-menu-add-button:hover {
  }
}

.container-accordion {
  margin-top: 20px;
  max-height: 50vh;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #081e46;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #007ec1;
    border-radius: 10px;
    border: 2px solid #081e46;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #3b8fbd;
  }

  .accordion-header:hover {
    background-color: var(--secondary-color);
  }

  .accordion-header {
    display: flex;
    align-items: center;
    justify-content: start;
    padding: 12px;
    background-color: transparent;
    color: #ffffff;
    cursor: pointer;
    font-size: 16px !important;
    transition: background-color 0.3s ease;

    .css-ahj2mt-MuiTypography-root {
      font-weight: 500;
    }

    .dx-theme-material-typography {
      font-family:
        Roboto,
        RobotoFallback,
        Noto Kufi Arabic,
        Helvetica,
        Arial,
        sans-serif;
      font-weight: 500;
    }

    .accordion-text {
      flex: 0.5;
      margin-right: 10px;
    }

    .accordion-icon {
      font-size: 1 rem;
    }

    .css-ahj2mt-MuiTypography-root {
      font-size: 16px;
    }
  }

  .css-1aj41gs {
    background-color: inherit !important;
    color: inherit !important;
    box-shadow: inherit !important;
  }

  .css-15v22id-MuiAccordionDetails-root {
    padding: 0 !important;
  }

  .css-1086bdv-MuiPaper-root-MuiAccordion-root {
    box-shadow: none !important;
    background-color: transparent !important;
  }

  .accordion-content-container {
    background-color: #061939;
    padding: 0 !important;
    color: #ffffff;
    border: none !important;
    box-shadow: none !important;
    font-family:
      system-ui,
      -apple-system,
      BlinkMacSystemFont,
      'Segoe UI',
      Roboto,
      Oxygen,
      Ubuntu,
      Cantarell,
      'Open Sans',
      'Helvetica Neue',
      sans-serif;
    font-size: 14px;

    .sub-menu-item {
      margin: 5px;
      cursor: pointer;
      margin-left: 20px;
      .css-ahj2mt-MuiTypography-root {
        font-size: 14px;
      }

      &:hover {
        margin-left: 18px !important;
      }
    }

    .sub-menu-item.selected {
      color: #024e9b;
      // padding-left: 10px;
      margin-left: 18px !important;
    }
  }
}
