.table {
  border: 1px solid lightgray;
  border-radius: 10px;

  .button {
    text-transform: none;
    text-decoration: underline;
  }
}

.add-button {
  display: flex;
  justify-content: end;
  margin-bottom: 10px;
}
