.new-keywordModels {
  margin: 40px 0px;

  .MuiOutlinedInput-root {
    border-radius: 10px;
    align-items: center;
  }

  .title-with-button {
    width: 100%;
    height: 100%;
    justify-content: right;
    align-content: center;

    .MuiButtonBase-root {
      margin-top: 10px;
    }
  }

  .add-keyword-phrase {
    margin-top: 10px;
    width: 100%;

    .MuiButtonBase-root {
      align-content: center;
      height: 100%;
      display: flex;
      width: 100%;
    }
  }

  .autocomplete {
    .MuiInputBase-root {
      padding: 4px;
    }
  }
}
