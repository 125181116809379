.app-selector__container {
  .app-selector__paper {
    padding: 24px;
    border-radius: 8px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);

    .roles-table-new-row {
      .MuiTableCell-root {
        padding-top: 0;
        padding-bottom: 0;
      }

      .MuiInputBase-input {
        height: 55px;
        padding: 0 15px;
      }
    }
  }

  .app-selector__title {
    font-weight: 600;
  }

  .app-selector__select {
    width: 100%;

    .MuiOutlinedInput-notchedOutline {
      border-color: #1976d2;
    }

    &:hover .MuiOutlinedInput-notchedOutline {
      border-color: #115293;
    }
  }
}

.permissions-table-new-row {
  .MuiTableCell-root {
    padding-top: 0;
    padding-bottom: 0;
  }

  .MuiInputBase-input {
    height: 54px;
    padding: '0px';
  }
}
