.accordion-content-container {
  font-size: 14px;
}

.accordion-header {
  font-size: 16px;
  font-weight: 700;
}

.dx-field-item-label-location-top {
  padding-inline-start: 0px;
  font-size: 12px;
}

.dx-texteditor-input {
  font-size: 14px;
}

.dx-texteditor.dx-editor-filled {
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.04);

  &:hover {
    background-color: rgba(0, 0, 0, 0.04) !important;
  }

  &:not(:hover) {
    background-color: rgba(0, 0, 0, 0.04) !important;
  }
}

.dx-field-item-label-content {
  .dx-field-item-label-text {
    color: #969696;
  }
}

.dx-texteditor-input {
  color: #061939;
}
