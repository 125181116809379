.root-menu-container {
  display: flex;
  flex-direction: column;
  height: 100vh; /* Para asegurar que ocupe toda la altura de la ventana */
  justify-content: space-between; /* Asegura que el logo se mantenga en la parte inferior */
  margin-bottom: 16px;

  .root-menu-header-wrapper {
    margin-top: 48px;
    display: flex;
    justify-content: space-between;

    .root-menu-title-primary {
      font-size: 32px !important;
      font-weight: 600 !important;
      margin-bottom: 0px;
      margin-top: 0px;
    }

    .dx-button {
      background: transparent;
    }

    .dx-menu-item-popout-container {
      display: none !important;
    }

    .dx-context-menu-container-border {
      color: #ffffff !important;
    }

    .dx-context-menu-item {
      color: #ffffff !important;
    }

    .dx-context-menu-item-expanded {
      color: #ffffff !important;
    }
  }

  .root-menu-title-secondary {
    font-weight: 600 !important;
    margin-top: 0px;
    color: #306eb6;
  }
}
