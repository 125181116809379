.tree-view-container {
  max-height: 60vh;
  margin-top: 60px;
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #081e46;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #007ec1;
    border-radius: 10px;
    border: 2px solid #081e46;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #3b8fbd;
  }

  .tree-view-item.selected {
    border-radius: 0px !important;
  }

  .tree-view-container::-webkit-scrollbar {
    width: 8px;
  }

  .tree-view-container::-webkit-scrollbar-thumb {
    background-color: #007ec1;
    border-radius: 10px;
    border: 2px solid #061939;
    .spinner {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
  }

  .tree-view-container::-webkit-scrollbar-track {
    background: #061939;
    border-radius: 10px;
  }

  .tree-view-container::-webkit-scrollbar-thumb:hover {
    background-color: #3b8fbd;
  }

  .sidenav-accordion::-webkit-scrollbar {
    width: 8px;
  }

  .sidenav-accordion::-webkit-scrollbar-track {
    background: #061939;
  }

  .tree-view-item:hover {
    border-left: #306eb6 2px solid;
    cursor: pointer;
    background-color: transparent;
    padding: 5px;
  }
  .tree-view-item {
    padding: 8px 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 200px;
    .styledAddIcon {
      margin-left: auto;
      margin-right: 12px;
      .icon {
        font-size: 1rem;
      }
    }
  }
  .tree-view-item.selected {
    border-left: #306eb6 2px solid;
    background-color: transparent;
    color: #024e9b;
    border-radius: 4px;
  }

  .dx-treeview-item {
    background-color: transparent;
  }

  .dx-treeview-item:hover {
    background-color: transparent;
    color: #306eb6;
  }

  .dx-state-hover {
    background-color: transparent !important;
  }

  .dx-treeview-node .dx-treeview-item .dx-state-focused {
    background-color: transparent !important;
  }

  .non-selectable {
    color: white !important;
    pointer-events: none;
  }

  .tree-view-item {
    padding: 5px;
  }

  .tree-view-item.title {
    font-weight: 500;
  }

  .tree-view-item:hover {
    color: #306eb6;
  }

  .dx-treeview {
    margin-top: 0px;
  }
}

.dx-datagrid-nodata {
  display: none;
}
