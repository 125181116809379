.supplier-popup {
  .MuiDialogTitle-root {
    font-size: 18px;
    background-color: rgb(0, 64, 110);
    color: white;
    font-weight: bold;
  }

  .MuiDialogActions-root {
    justify-content: space-between;
  }

  .MuiDialogContent-root {
    margin-top: 25px;
  }

  .MuiTextField-root {
    margin-bottom: 30px;
  }
}
