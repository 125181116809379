.select-box {
  .dx-placeholder {
    font-size: 13px;
  }

  .dx-button-text {
    font-size: 14px;
    text-transform: uppercase;
  }

  .dx-button {
    margin-left: 10px;
  }

  .dx-button-content {
    border-radius: 10px;

    &:hover {
      background-color: rgba(30, 152, 204, 0.37) !important;
    }

    > .dx-inkripple > .dx-inkripple-wave {
      background-color: rgba(30, 152, 204, 0.37);
    }
  }

  .padding-left {
    padding-left: 10px;
  }

  .dx-item .dx-box-item .dx-item-content .dx-box-item-content {
    flex: 1;
    padding: 0 5px;
  }
}
