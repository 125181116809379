.dx-swatch-additional,
.side-navigation-menu {
  background-color: var(--primary-color) !important;
}

.submenu-return-container {
  margin-top: 32px;
  display: flex;
  justify-content: flex-start;
  background-color: transparent;

  .dx-button-content {
    font-size: 22px !important;
  }
  .submenu-return-button:hover {
    background-color: #1b5379 !important;
  }
}

.submenu-input-container {
  margin-top: 32px;
  margin-bottom: 32px;
  display: flex;
  justify-content: center;

  .submenu-input-search {
    all: unset;
    height: 50px;
    width: 240px;
    margin: 0 auto;
    padding: 12px 24px;
    border-radius: 10px;
    background: rgba(249, 249, 249, 0.2);
    box-sizing: border-box;
  }

  .submenu-input-search::placeholder {
    color: #b0b0b0;
  }
}

.sidenav-accordion {
  max-height: 60vh;
  overflow-y: auto;
  padding-right: 10px;
  margin-top: 32px;
  max-height: 45vh;
}

.sidenav-accordion::-webkit-scrollbar {
  width: 8px;
}

.sidenav-accordion::-webkit-scrollbar-track {
  background: #061939;
}

.sidenav-accordion::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
  border: 2px solid #061939;
}

.sidenav-accordion::-webkit-scrollbar-thumb:hover {
  background: #555;
}
