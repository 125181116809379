.risk-case-popup {
  .dx-overlay-content {
    .dx-popup-content {
      padding: 14px;
    }
  }

  .MuiTypography-root {
    font-weight: bold;
  }

  .dx-button-text {
    font-size: 13px;
    font-weight: bold;
  }

  .MuiAccordionDetails-root,
  .dx-field-item-label {
    padding-top: 0;
  }

  .spinner {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
