.icon {
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon.green {
  color: green;
}

.icon.red {
  color: red;
}

.dx-datagrid-rowsview .dx-row .dx-cell {
  display: flex;
  justify-content: center;
  align-items: center;
}
