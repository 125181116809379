.risk-models-page-container {
  margin: 41px 46px 41px 46px;

  .risk-model-title {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .dx-tabs-wrapper {
    & > .dx-tab {
      max-width: 130px;
    }
  }

  .dx-tab .dx-tab-text {
    font-weight: 400;
    font-size: 14px;
  }

  .dx-state-hover,
  .dx-state-focused,
  .dx-state-active {
    background-color: #ffffff !important;
  }
}
