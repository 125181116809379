.tagbox-text-field {
  .MuiInputBase-root {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    min-height: 56px;
  }
}

.select-box-text-field {
  // 	.MuiInputBase-input {
  // 		padding-top: 0px !important;
  // 		padding-bottom: 0px !important;
  // 		min-height: 56px !important;
  // 	}
  // 	.MuiInputBase-input > div {
  // 		padding: 12px 0;
  // 	}

  .MuiChip-root {
    margin: 0 3px;
  }
}
