.admin-grid h5 {
  background-color: #306eb6;
  color: white;
  text-indent: 8px;
  text-transform: uppercase;
  margin-bottom: 0px;
}

#sort-order-grid {
  width: 450px;
}
