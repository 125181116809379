.root-menu-item {
  padding-left: 16px;

  &:hover {
    color: #306eb6;
    border-left: #306eb6 2px solid;
    cursor: pointer;
    margin-left: -2px;
  }

  &.selected {
    color: #306eb6;
    border-left: #306eb6 2px solid;
    margin-left: -2px;
  }

  &-text {
    margin: 20px 0;
    font-size: 16px;
  }
}

.sub-menu-item {
  padding-left: 16px;
  font-size: 14px;

  &:hover {
    color: #306eb6;
    border-left: #306eb6 2px solid;
    cursor: pointer;
    margin-left: -2px;
  }

  &.selected {
    color: #306eb6;
    border-left: #306eb6 2px solid;
    margin-left: -2px;
  }

  &-text {
    margin: 12px 0;
    font-size: 14px;
  }
}
