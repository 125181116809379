.nsn-query-container {
  display: block;
}

/*
.rcorners {
	border-radius: 25px;
	border: 2px solid #c1d4e6;
	padding: 20px;
	-webkit-box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.75);
	box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.75);
}
*/

.rcorners {
  border: 2px solid #306eb6;
  padding: 20px;
}
