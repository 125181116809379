.model-execution-confirmation-dialog {
  .MuiDialogTitle-root {
    background-color: #00406e;
    color: white;
  }

  .MuiDialogContentText-root {
    color: black;
  }

  .MuiDialogContent-root {
    padding-bottom: 0;
  }

  .MuiDialogActions-root {
    justify-content: center;
  }

  .MuiDialogContent-root {
    align-content: center;
    text-align: center;
  }
}
