.dialog-title {
  background-color: #007ec1;
  color: #ffffff;
  padding: 15px;
  font-size: 1.5rem;
  text-align: start;
}

.riskCase-name {
  color: #007ec1;
  text-decoration: none;
  cursor: pointer;
  transition:
    color 0.3s ease,
    text-decoration 0.3s ease;
}

.riskCase-name:hover {
  color: #015988;
  text-decoration: underline;
}
.dialog-scrollbar::-webkit-scrollbar {
  width: 10px;
}

.dialog-scrollbar::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 10px;
}

.dialog-scrollbar::-webkit-scrollbar-thumb {
  background-color: #007ec1;
  border-radius: 10px;
  border: 2px solid #fff;
}

.dialog-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #3b8fbd;
}
