.container {
  position: relative;
  width: 100%;

  .chartContainer {
    width: 100%;
    padding-right: 220px;

    .recharts-legend-item-text {
      font-size: 16px !important;
    }
  }

  .selectControl {
    position: absolute;
    top: 60px;
    right: 20px;
    width: 200px;
  }
}
