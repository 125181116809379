.data-grid-base {
  .MuiButtonBase-root {
    text-decoration: underline;
    text-transform: none;
    background: none;

    &:hover {
      background: none;
      text-decoration: underline;
    }

    &:focus {
      outline: none;
    }
  }
}
