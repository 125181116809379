.MuiDataGrid-columnHeader:focus-within,
.css-60ds9y-MuiDataGrid-root .MuiDataGrid-cell:focus-within {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}

.grid-textfield {
  padding: 0 !important;
}
