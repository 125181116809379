.life-stage-container {
  display: flex;
  flex-direction: column;
  align-items: start;
  background-color: #ffffff;
  border: none !important;
  padding: 0 !important;
  .life-stage-container:focus {
    outline: none !important;
  }

  .life-stage-container:active {
    border: none !important;
  }

  .tooltip {
    border: none !important;
  }
  .pie-chart {
    outline: none !important;
    border: none !important;
  }

  .recharts-sector {
    outline: none !important;
    border: none !important;
  }
}

.recharts-layer {
  outline: none !important;
  border: none !important;
}

.life-stage-color {
  margin-right: 5px;
  font-size: 16px !important;
  background-color: #ffffff;
  font-weight: 400;
}
