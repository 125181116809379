.action-types-container {
  .action-type-label {
    // background-color:#fff !important;
    // box-shadow:  2px 4px rgba(0, 0, 0, 0.1);
    // border:none

    background-color: #fff; // Fondo gris claro
    color: #000 !important;
    font-weight: 500; // Color del texto negro
    margin: 3px;
    box-shadow: 2px 4px rgba(0, 0, 0, 0.1);
    padding: 4px; // Espaciado interno
    font-size: 0.8rem; // Tamaño de fuente
    width: 500px;
    -webkit-text-fill-color: none !important;
    border-radius: 4px;
  }
  .MuiBox-root {
    background-color: #fff !important;
    border: none !important;
  }
  .Mui-disabled {
    color: black !important;
    -webkit-text-fill-color: black !important;
  }
}
