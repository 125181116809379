.help-icon-container {
  position: relative;
  margin-left: 10px;
  .help-icon {
    position: absolute;
    cursor: pointer;
  }
}

.checkbox-container {
  .help-icon {
    top: 12px;
  }
}

.typography-container {
  .help-icon {
    top: 4px;
    left: 10px;
  }
}
