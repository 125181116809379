.risk-discovery-container {
  margin: 41px 46px 41px 46px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  h4 {
    margin: 0;
  }
}
