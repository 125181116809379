.nsn-detail-grid h5 {
  background-color: #306eb6;
  color: white;
  text-indent: 8px;
  text-transform: uppercase;
  margin-bottom: 0px;
}

.grid-link {
  font-weight: bold;
  text-decoration: none;
}

.bold-row {
  font-weight: bold;
}

.red-cell {
  color: red;
}

.cell-clickable {
  cursor: pointer;
}
