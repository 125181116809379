.filing-datagrid-primary-title {
  font-size: 18px;
  font-weight: 500;
}

.filing-datagrid-primary-subtitle {
  font-size: 16px;
  font-weight: 400;
  padding-left: 20px;
}

.filing-datagrid-secondary-title {
  font-weight: 500;
}
